/*   ADMIRE ADMIN Project CSS Table of Content  */
/*

    1. Reset css
    2. Nav bars
      2.1 Top bar
      2.2 Left menu
      2.3 Multi level menu
      2.4 Right bar
      2.5 Template Layouts
    3. Body header
    4. Common css
    5. Users css
    6. Layout pages css
    7. Content & header right section
    8. Tables
    9. Widgets css
    10. fixed menu header css
   11. Media queries.



/*
/* ================ 1.Reset css========================== */


.card-header
{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
.card{
  margin-bottom:0;
}
.disabled{
  opacity:0.65;
}
.card-block {
  padding: 0 1.25rem 2rem 1.25rem;
}
.custom-control-indicator{
  pointer-events: all !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -4 7 9'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.bg-primary, .bg-danger, .bg-success, .bg-warning, .bg-info, .bg-mint
{
  color: #fff;
}
.btn
{
  outline: none !important;
  cursor: pointer;
}
a.btn:focus{
  color: #fff;
}
a.btn-secondary:focus {
  color: #000;
}
/*====================Reset css end============================*/
/*========================2.Pre Loader========================*/



/*===========================End of loader======================*/

/*============================2. Nav bars=========================*/

/* 1.top bar */
#top .navbar {
  padding: 0;
  margin-bottom: 0;
  background: #4E4C4C;
}
#top .topnav {
  padding:0 !important;
  text-align: left;
}
.menu{
  float: left;
}
#menu-toggle .fa{
  font-size: 18px;
}
.menu{
  margin-top: 16px;
  margin-left: 22px;
  cursor: pointer;
}
.micheal_btn{
  padding-right: 2px !important;
}
#top .navbar-nav>li>.dropdown-menu {
  background: #ff8080;
}

#top .nav>li:hover, #top .nav>li:focus {
  background: #ff8080;
}

#top .nav>li:hover, #top .nav>li.active {
  background: #ff8080;
}

#menu li {
  border-bottom: none;
}
#menu ul {
  background: #7a7a7a;
}
#menu>li.active {
  background: #ff8080 !important;
}
#menu li.active a {
  color: #fff;
}
#menu li.active>ul {
  background: #7a7a7a !important;
}
#menu li.active ul li.active>a {
  color: #fff;
  background-color: #ff8080;
}
#menu li:hover {
  background: #3a3a3a;
}
#menu li ul li:hover {
  background: #3a3a3a;
}

#menu li.active ul li:hover>a{
  color: #fff;
}


#top .navbar-brand {
  padding-top: 16px;
  font-size: 18px !important;
}
.user-info li {color: #ccc;}

.top_menubar li {
  padding: 4px 5px;
}
.top_menubar li a   {
  padding: 9px 7px;
}
.card:hover
{
  box-shadow: -2px 0 46px -5px #9e9c9e;
}
.messages
{
  border: 1px solid white;
  border-radius: 3px;
  line-height: 19px;
}

.dropdown-item.title
{
  margin-top: -7px;
  font-weight: bold;
  padding: 13px;
  font-size: 14px;
  background-color: #e6e6e6;
  text-align: center;
}
.without_border
{
  border:0;
  background-color: #ccc;
}
.user-info
{
  padding-top: 30px;
  float: left;
}
.admire_admin
{
  margin-top: 14px;
}
.drop_box_align
{
  margin-top: 6px;
}
.calendar_badge
{
  margin-right: 10px;
}
#top .nav>li:hover, #top .nav>li:focus {
  background: #ff8080;
  color: #fff;;
}
#top .nav>li>a:hover, #top .nav>li>a:focus {
  color: #fff !important;
}
#menu li.active ul li.active>a {
  color:#fff;
}
#menu li a {
  padding: 15px 8px 15px 15px;
  border-bottom: none;
  color: #fff;
}
#menu li ul li a {
  padding: 10px 8px 10px 30px;
}
#menu li ul li .sub-menu1 li a{
  padding-left: 45px;
}
#menu li ul li .sub-menu1 li .sub-menu2 li a{
  padding-left: 60px;
}
#menu li ul li .sub-menu1 li .sub-menu2 li .sub-menu3 li a{
  padding-left: 75px;
}
#menu li ul li .sub-menu1 li .sub-menu2 li .sub-menu3 li .sub-menu4 li a{
  padding-left:90px;
}

/* END TOP bar */


/* 2.Left Menu */
#left {
  background-color: #4E4C4C;
  margin-top: -2px;
}
.search_bar
{
  margin: 10px 0 20px 0;
}
.head {
  border-bottom: 1px solid #E8E8E8;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
  color:#747474;
  background-color: #fff;
}
#top .user-settings .btn,#top .user-settings .btn:focus{
  outline: none !important;
}
#top .user-settings .btn:focus{
  box-shadow:none;
}
.head .search-bar {
  margin: 0 auto;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.head .search-bar .main-search {
  border-bottom: 1px solid #7c7c7c;
}
.head .search-bar .main-search input[type="text"] {
  background-color: transparent;
  border-color: transparent;
  color: #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.toggle_icon {color:#000; border:1px solid #fff;}
.head .search-bar .main-search button, .head .search-bar .main-search input[type=submit] {
  background: transparent;
  border: transparent;
}
@media (min-width: 768px) {
  .head .search-bar {
    border-bottom-width: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.05) inset;
  }
  .side-right .head .search-bar {
    border-right-width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.05) inset;
  }
}
.head .main-bar {
  padding:5px 22px;
}
.head .main-bar h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #bababa;
}
@media (min-width: 768px) {
  .head .main-bar h3 {
    margin-top: 5px;
  }
}
@media (min-width: 768px) {
  .head .search-bar .main-search {
    margin-right: auto;
  }
}
.admin_img{
  width: 45px;
  height: 30px;
  margin-top: -10px;
}
.admin_img2{
  width: 30px;
  height: 30px;
}
.admin_img3{
  width: 64px;
  height: 64px;
}
.user-media .user-link {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.user-media .user-link {
  float: left;
}
.user-media .user-link .user-img {
  margin: 10px 10px 10px 17px;
  float: left;
}
.user-media .media-body {
  display: block;
  float: left;
  margin-left: 12px;
}
.user-media .media-body h5.media-heading {
  color: #fff;
  padding-top: 8px;
  margin-bottom: 3px;
}
.user-media .media-body ul.user-info li {
  color: #ccc;
}
.side-right .user-link .user-label {
  left: 6px;
  right: auto;
}
.sidebar-left-mini .user-wrapper {
  position: absolute;
  top: 0;
  left: -100%;
  display: none;
  min-width: 250px;
}
.user-media-toggleHover {
  display: none;
}
.sidebar-left-mini .user-media-toggleHover {
  font-size: 18px;
  color: #fff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  display: inherit;
}
.sidebar-left-mini .user-media:hover .user-wrapper {
  left: 100%;
  display: inherit;
  z-index: 9999;
}
.sidebar-left-mini.side-right .user-media:hover .user-wrapper {
  left: auto;
  right: 100%;
}
#menu, #menu ul, #menu li, #menu a {
  padding: 0;
  margin: 0;
  color: #f4f4f4;
}
#menu, #menu ul {
  list-style: none;
}
#menu li, #menu a {
  position: relative;
  display: block;
}

#menu a, #menu a:hover, #menu a:focus, #menu a:active {
  text-decoration: none;
}
#menu ul {
  background: rgba(236, 102, 72, 0.05);
}
/* multi level menu */
#menu li ul.sub-menu> li>a{
  padding-left: 32px;
}
#menu ul> li> ul.sub-submenu> li>a{

  padding-left: 50px;
}
#menu ul> li> ul> li> ul.sub-submenu> li>a{

  padding-left: 65px;
}
#menu ul> li> ul> li> ul.sub-submenu> li>ul>li>a{

  padding-left: 80px;
}
#menu ul> li> ul> li> ul.sub-submenu> li>ul>li>ul>li>a{

  padding-left: 95px;
}
/*end multi level menu */

#menu.affix {
  position: fixed;
  width: 250px;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
#menu.affix:hover {
  overflow-y: scroll;
}
#menu .nav-divider {
  height: 1rem;
  margin: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) inset;
}
.sidebar-left-mini #menu .nav-header {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}
#menu .fa.arrow {
  float: right;
  padding-top: 3px;
  margin-right: 15px;
}
#menu .fa.arrow:before {
  content: "\f104";
}
#menu .active > a > .fa.arrow:before {
  content: "\f107";
}
.badge{
  padding: 0.4em 0.4em;
}
.badge-pill{
  padding-left: 0.6em;
  padding-right: 0.6em;
}
/* END LEFT Styles*/

/* 3 Right bar */

.skin_btn {display:inline-table; cursor:pointer; width:50px; height:30px; margin-bottom: 7px;}
.skinmulti_btn {display:inline-table; cursor:pointer; width:50px; height:40px; margin-bottom: 12px;}

.skin_size { width:50px; height:20px;}

.skin_black {background-color:#575757; }
.skin_blue {background-color:#539CC1;}
.skin_green {background-color:#7F9E40; }
.skin_purple {background-color:#E07298; }
.skin_orange {background-color:#ffb300; }
.skin_red {background-color:#e74c4c; }
.skin_mint {background-color:#0fb0c0;}
.skin_brown {background-color:#825228;}
.brown_txt {color:#9C6426;}
.brown_bg {background-color:#9C6426 !important;}
.mint_bg {background-color:#0fb0c0;}

.skin-icon {color:#fff; padding:5px; border-radius:15px; width:30px; height:30px;}
.margin15_bottom {
  margin-bottom: 15px;
}
/*end right bar*/


/* 4 Template layouts */
#wrap:before, #top:before, .head:before, .Footer:before, #wrap:after, #top:after, .head:after, .Footer:after {
  content: " ";
  display: table;
}
#wrap:after, #top:after, .head:after, .Footer:after {
  clear: both;
}
.Wrap, #wrap {
  margin: 0 auto -20px auto;
  position: relative;
}
#left, #right {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 250px;
  z-index: 999;
}
#left {
  position: absolute;
  left: -250px;
  height:0;
  overflow:hidden;
}
#right {
  position: fixed;
  top:3px;
  bottom: 0;
  overflow-y: auto;
  right: -250px;
  background-color: #fff;

}
#right .fa-stack
{
  margin-left: -2px;
}
.xs_skin_hide
{
  float: right;
  right: 4px;
  color: #525252;
  font-size: 14px;
  border: 1px solid black;
  padding: 3px 10px;
  border-radius: 5px;

}
.sidebar-left-opened #left {
  left: 0;
  height:auto;
  background-color: #4E4C4C;
}
.sidebar-right-opened {
  position: relative;
  right: 250px;
}
.sidebar-right-opened #right {
  right: 0;
}

.sidebar-right-opened.fixed .navbar-fixed-top {
  right: 0;
}
.right_progressbar_section .progress {
  height: 0.7rem;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  #left {
    position: relative;
    float: left;
    left: auto;
    height:auto;
    background-color: #4E4C4C;

  }
  .sidebar-left-hidden #left {
    width: 0;
    height:0;
    overflow:hidden;
    visibility: hidden;
  }
  .sidebar-left-mini #left {
    width: 50px;
  }

  #content {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
  .sidebar-right-opened {
    right: 0;
    margin-right: 250px;
  }
  .sidebar-right-opened .navbar-fixed-top {
    margin-right: 250px;
  }
}
@media (min-width: 768px) {
  body.boxed {
    margin-bottom: 0;
  }
  body.boxed .Wrap, body.boxed #wrap, body.boxed .Footer, body.boxed #top .navbar.navbar-fixed-top {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  body.boxed .Footer, body.boxed #footer {
    position: relative;
  }
}
/* END Template Layout */
/*============================end nav bar==============================*/


/*=========================3. Body header=============================*/
.nav_top_align{
  margin-top:25px;
}
.nav_breadcrumb_top_align{
  margin-top: 12px;
  margin-bottom: 11px;
}
.main-bar .breadcrumb>li+li:before {
  padding: 0 5px;
  color: #999;
  font: normal normal normal 14px/1 FontAwesome;
}
.main-bar.row{
  margin: 0 ;
}

/*=========================end body header============================*/
/*=========================4. Common css============================*/
.text-white
{
  color: #fff;
}
.text-mint
{
  color: #0fb0c0;
}
.text-brown
{
  color: #9C6426;;
}
.bg-white{
  background-color: #fff;
}
.b-r-0
{
  border-radius: 0;
}
.m-r-0{
  margin: 0;
}
.m-t-5{
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15{
  margin-top: 15px;
}
.m-t-20{
  margin-top: 20px;
}
.m-t-25{
  margin-top: 25px;
}
.m-t-30{
  margin-top: 30px;
}
.m-t-35{
  margin-top: 35px;
}
.m-t-40{
  margin-top: 40px;
}
.m-l-0{
  margin-left: 0;
}
.m-l-10{
  margin-left: 10px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-b-0{
  margin-bottom: 0;
}
.m-b-10{
  margin-bottom:10px;
}
.m-b-20{
  margin-bottom: 20px;
}
.p-b-20{
  padding-bottom: 20px;
}
.p-t-25{
  padding-top: 25px;
}
.p-t-15{
  padding-top: 15px;
}
.p-t-10{
  padding-top: 10px;
}
.p-l-0{
  padding-left: 0;
}
.p-r-0 {
  padding-right: 0;
}
.p-lr-0{
  padding-left: 0;
  padding-right: 0;
}

.p-l-5{
  padding-left: 5px;
}
.p-d-0{
  padding: 0;
}
.input_field_sections{
  margin-top: 35px;
  padding: 0 30px 0 15px;
}
.has-error label, .has-error span, .has-error small{
  color: #ff8080;
}
.search_bar input
{
  border: 1px solid #fff;
}
.navbar-brand h4
{
  font-size: 18px;
}
/*===========================common css end=============================*/


/*=============================5. Users css==============================*/
.users_grid_tools .tools a{
  background-color:#424242;
  border:1px solid gray;
  padding: 8px;
}
.view_user_nav_padding{
  padding:12px 0 2px 20px !important;
}
.view_user_nav_padding>li>a{
  color: #000;

}
.view_user_nav_padding>li>.active{
  padding-bottom: 10px;
  border-bottom: 4px solid #ff8080;
  position: relative;

}
.inner hr{
  margin-top: -6px;
}
.card_nav_hover>a:hover{
  padding-bottom:10px;
  border-bottom: 4px solid red;
}

.card_nav_body_padding{
  padding: 15px;
}
a.list-group-item {
  color: #555;
  text-decoration: none;
}
/*add users*/

#tryitForm .has-error label{
  color: #ff8080;
}
#tryitForm .has-error small{
  font-size: 14px;
  padding: 0;
}
#tryitForm .help-block{
  padding-left: 15px;
}
#tryitForm .fileinput img{
  width: 230px;
  height: 172px;
}
.help-block{
  font-size: 14px !important;
}
.fileinput-new,.fileinput-preview img{
  width: 240px;
  height: 180px;
}
.img-thumbnail {
  background-color: #fff !important;
}
.custom-control-description{
  color:#5a5a5a !important;
}
.gender_message .custom-controls-stacked{
  overflow:auto;
}
.gender_message .custom-radio .custom-control-indicator{
  margin-left:3px
}
#select2_sample_chosen{
  width: 100% !important;
}
/*grid css start*/
.form_div> div{
  padding: 10px;
}
#search input
{
  width: 67%;
}
/*grid css end*/
/*=========================users css end===========================*/

/* =====================6 Layout pages css======================== */

.card_block_top_align {
  margin-top: 15px;
}
.fixed-header_menu {
  position: fixed !important;
  top: 0 ;
  bottom: 0;
  overflow-y: auto;
  margin-top: 53px !important;
  min-height: 0 !important;
}
@media  (max-width: 539px){
  .fixed-header_menu {
    margin-top: 100px !important;
  }
}
.noheader_logo{
  padding: 15px 0 0 15px;
}
.sidebar-right-opened .fixed_header_menu_conainer{
  margin-left:0;
}

/*=======================Layout pages css end=====================*/

/*============================7 Maps css============================*/
.search_route_maps_buttons_margin{
  margin: 20px 0 0 37px;
}
.search_route_maps_buttons_margin a{
  padding: 5px 10px;
}
.maps_body{
  margin: 0;
}
#get_route,#back,#forward{
  margin: 10px 5px 10px 0;
}
//jqvmaps
.vector_maps{
  height: 400px;
}
@media  (max-width: 424px) {
  .vector_maps{
    height: 250px;
  }
}
.vector_maps .jqvmap-zoomin,.vector_maps .jqvmap-zoomout{
  background-color: #668cff;
  width: 15px;
  height: 15px;
}
.jqvmap-zoomin, .jqvmap-zoomout {
  padding: 2px;
}
/*=============================end map css=========================*/



/*====================8 Content & header right section==============*/
#content {
  transition: width 0.4s;
  padding-bottom: 15px;
}
.outer {
  padding: 10px;
  margin-top: 25px;
}
.outer:before, .outer:after {
  content: " ";
  display: table;
}
.outer:after {
  clear: both;
}
.inner {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  background: #fff;
}
.inner hr {
  border-top-color: #ccc;
}
body.error {
  padding-top: 40px;
}
body.error .logo h1 {
  color: #FFFFFF;
  font-size: 100px;
  text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbbbbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}


.skin_txt {color: #4f4d4d;}

.user-media .media-body ul.user-info li {color: #ccc;}

@media (max-width: 767px) {
  body.error .logo h1 {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .inner {
    float: left;
    width: 100%;
  }
}
/* END CONTENT STYLES */


/* top right menu dropdowns */

.notifications .dropdown-menu {
  width: 250px;
  left: -33%;
  top: 35px;
}

.messages .dropdown-menu{
  width: 220px !important;
}

.user-settings .dropdown-menu {
  width: 167px;
  left: -48px;
  top: 36px;
}
.notifications .data {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px 3px 15px;
  display: inline-block;
  width: 100%;
  margin-top: -5px;
  cursor: pointer;
  font-size: 12px;
}

.notifications .message-data {
  max-width: 100%;
  min-width: 30px;
  height: auto;
  margin-left: -7px;
  padding-top: 6px;
}

.notifications .popover-title {
  margin-top: -7px;
  font-weight: bold;
  font-size: 12px;
  background-color: #e6e6e6;
  text-align: center;
}

.notifications .dropdown-menu:before {
  position: absolute;
  top: -9px;
  left: 11%;
  display: inline-block;
  content: '';
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ccc;
  border-bottom-color: #e6e6e6;
  border-left: 10px solid transparent;
}
#notifications .data:hover, #messages .data:hover
{
  background-color: #eee;
}

.messages .dropdown-menu:before {
  position: absolute;
  top: -9px;
  left: 14%;
  display: inline-block;
  content: '';
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ccc;
  border-bottom-color: #e6e6e6;
  border-left: 10px solid transparent;
}

.user-settings .dropdown-menu:before {
  position: absolute;
  top: -9px;
  left: 80%;
  display: inline-block;
  content: '';
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ccc;
  border-bottom-color: #e6e6e6;
  border-left: 10px solid transparent;
}

.popover-footer {
  padding: 8px 14px;
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  background-color: #e6e6e6;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 5px 5px;
  text-align: center;
  margin-bottom: -8px;
}
.notifications .popover-footer>a{
  display: block;
}
.notifications .message-img {
  max-width: 100%;
  min-width: 30px;
  height: auto;
  margin-left: -7px;
  padding-top: 6px;
}

.no-bg {
  background: none !important;
}
.bg-container {
  background: #eaeaea;
  color: #525252;
}

.user-settings button {
  border: none;
  color: #fff
}
.user-settings .dropdown-menu li a span {
  float: left;
  display: inline-block;
  color: #aaa;
  padding-right: 10px;
}

.user-settings .dropdown-menu li a {
  padding: -1px;
  display: table;
  width: 100%;
  font-size: 13px;
  letter-spacing: 0.05em;
}

.user-settings .popover-title {
  margin-top: -5px;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  background-color: #e6e6e6;
  text-align: center;
}
.well {margin-left:5px; background-color: #fefefe; border-color: #fefefe; margin-bottom:10px; padding: 0 8px 0 5px;}
#right .alert {margin-left: 5px;}
.well.well-small.dark{
  margin-bottom: -15px;
}
.brown_txt.margin15_bottom{
  margin-bottom: 7px !important;
}
.right_progressbar_section .brown_txt{
  margin-top: 47px !important;
}
.well.well-small.dark.right_progressbar_section .brown_txt{
  margin-bottom: 0 !important;
}
#right .brown_txt{
  margin-top: 35px;
}
/*=====================end Content & header right section==============*/




/*==================================9 Tables==========================*/


table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}
table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

/*================================end tables=============================*/
/*================================10. fixed menu header css=================*/
@media (min-width: 768px) {
  .fixed{
    position: fixed !important;
  }
  #top.fixed{
    left: 0;
    right: 0;
    z-index: 91;
  }
  .fixed_menu #content{
    margin-left: 250px;
  }
  .fixed_menu.sidebar-left-hidden #content{
    margin-left: 0;
  }
  .fixedMenu_header.sidebar-left-hidden #content{
    margin-left: 0;
  }
  .sidebar-right-opened #top.fixed{
    right:250px;
  }
  /* ====fixed menu =====*/
  .boxed #top.fixed{
    left:5%;
    right: 5%;
  }
  .no_header #content{
    margin-left: 250px;
  }
  .fixed_menu .rightsidebar-without-nav{
    margin-top: -60px !important;
  }
  .no_left #content{
    margin-left: 0 !important;
  }
  .no_header.fixed_menu .rightsidebar-without-nav {
    margin-top: 0 !important;
  }
}

/*================================end fixed menu css=================*/
/*=============================11 Media queries=========================*/
@media  (max-width: 546px){
  .preloader_img{
    left: 46%;
    top: 47%;
  }
}
@media (min-width: 768px) {
  .search-bar {
    float: left;
    padding: 10px 4px;
  }
  .side-right .search-bar {
    float: right;
  }
  .search-bar .input-small.form-control {
    border-radius: 3px;
  }
  #top .topnav {
    margin: 7px 0 7px 0;
  }
  .top_menu {
    margin-top: 1px;
    padding: 0 0 2px 45px;
  }
  .head .search-bar {
    border-bottom-width: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.3);

    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.05) inset;
  }
  .side-right .head .search-bar {
    border-right-width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);

    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.05) inset;
  }
  .search-bar {
    float: left;
    padding: 10px 4px;
  }
  .side-right .search-bar {
    float: right;
  }
  .search-bar .input-small.form-control {
    border-radius: 3px;
  }
  .main-bar {
    display: block;
    overflow: hidden;
  }
  .head .search-bar .main-search {
    margin-right: auto;
  }
  .head .main-bar h3 {
    margin-top: 5px;
  }
}

@media  (max-width: 1240px) {
  .sidebar-right-opened #nav-content{
    width:30%;
  }
  .sidebar-right-opened .quick_text{
    display: none;
  }
}
@media (min-width: 992px) {
  .search-bar {
    width: 250px;
    padding: 10px 15px;
  }
  .search-bar .input-group-btn {
    display: table-cell;
  }
  .search-bar .input-small.form-control {
    border-radius: 3px 0 0 3px;
  }
  #top .topnav {
    margin: 5px 0 3px 0;
  }
}
@media  (max-width: 967px) {
  .advancnced_customized_fade{
    padding-top: 30px !important;
  }
}

@media  (max-width: 768px){
  .flip-scroll th {
    padding:11px ;
  }
  #top .topnav {
    margin: 6px 0 4px 0;
  }
}
@media (min-width:768px) and (max-width: 1200px) {
  .fixed-header_menu {
    position: fixed !important;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    margin-top: 53px;
  }
}
@media  (min-width: 767px) {
  .fixed_header_menu_conainer{
    margin-left: 250px;
  }
}
@media  (min-width: 422px) {
  .xs_skin_hide{
    display:none;
  }
}
@media (min-width: 320px) and (max-width: 382px) {
  .pagination_padding_general_components > li > a
  {
    padding: 5px 7px !important;
  }
  .nav-inline .nav-link
  {
    padding: 6px 9px;
  }
}
@media  (max-width: 991px) and (min-width: 498px) {
  .user-settings .dropdown-menu {
    top: 34px !important;
  }
}
@media  (max-width: 496px){
  .notifications .dropdown-menu,.user-settings .dropdown-menu{
    top: 33px !important;
  }
}
@media (min-width: 992px) and (max-width: 1023px){
  .top_menu {
    margin-top: 1px;
    padding: 0 0 2px 20px;
  }
}
@media (min-width: 1024px){
  .top_menu {
    margin-top: 1px;
    padding: 0 0 2px 30px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .messages .dropdown-menu, .notifications .dropdown-menu {
    top: 36px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .messages .dropdown-menu, .notifications .dropdown-menu {
    top: 34px !important;
  }
}
@media  (max-width: 504px) {
  .menu{
    float: right;
  }
}
/*boxed layouts*/
@media  (max-width: 1200px){
  .boxed_top_menu{
    padding: 0 0 2px 31px !important;
  }
}
@media  (max-width: 991px) {
  .media_max_991{
    margin-top: 35px;
  }
}
@media  (max-width: 573px){
  .media_max_573{
    margin-top: 35px;
  }
}
@media (min-width:768px)
{
  .boxed.fixed_header #top{
    left:5%;
    right:5%;
  }
}
/*================================media queries end======================*/